/* mask  input */
input {
  width: 100%;
  border: 1px solid #838383;
  box-shadow: none;
  background-color: #fff;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  padding: 11px 10px 10px 10px;
  color: #404040;
  font-size: 16px;
  letter-spacing: 2px;
  font-family: "GT Eesti";
  font-weight: normal;
  font-style: normal;
}

input:hover,
input:active,
input:focus {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  outline: 0;
  box-shadow: none;
  border: 1px solid #838383;
  box-shadow: none;
  background-color: #ededed;
  color: #404040;
}

.contests {
  color: black;
  text-decoration: underline;
  cursor: pointer;
}

/* -----------------------
Error message
------------------------*/

.error-message{
  margin-top:15px;
  color: #ee0200;
  font-weight: 500;
  font-size: 14px;

}

.error-message img {
  margin-top: -2px;
  margin-right: 3px;
}
