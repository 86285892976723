.loading {
    margin: 50% auto;
}

.loading--full-height {
    align-items: center;
    height: 100%;
}

.loading .loader {
    width: 48px;
    height: 48px;
    border: 5px solid #fff;
    /* !!!change border-bottom-color */
    border-bottom-color: #ed2b2e;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.popup-loader {
    border: none;
    outline: none;
}
.modal-content {
    background-color: inherit;
    border: 0px;
}
